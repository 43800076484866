var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "js-common-wrap" }, [
    _c("div", { staticClass: "js-system-authority" }, [
      _c(
        "div",
        { staticClass: "tree-box" },
        [
          _c("h3", { staticClass: "tree-header" }, [_vm._v("企业组名称")]),
          _c("el-tree", {
            attrs: {
              data: _vm.treeData,
              props: _vm.defaultProps,
              "node-key": "id",
              "default-expand-all": true,
              "highlight-current": true,
              "check-on-click-node": true,
            },
            on: { "node-click": _vm.handleNodeClick },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "js-common-head" },
          [
            _c("label", { staticClass: "label" }, [
              _vm._v("功能与数据权限管理"),
            ]),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.handleSave },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "js-common-content js-content-roleDetails" },
          [
            _c(
              "el-checkbox-group",
              {
                staticClass: "jurs-box",
                model: {
                  value: _vm.authority,
                  callback: function ($$v) {
                    _vm.authority = $$v
                  },
                  expression: "authority",
                },
              },
              _vm._l(_vm.authorityArr, function (auth) {
                return _c(
                  "el-checkbox",
                  { key: auth.id, attrs: { label: auth.id } },
                  [_vm._v(_vm._s(auth.name))]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }