<template>
  <div class="js-common-wrap">
    <div class="js-system-authority">
      <div class="tree-box">
        <h3 class="tree-header">企业组名称</h3>
        <el-tree 
          :data="treeData" 
          :props="defaultProps" 
          node-key="id" 
          @node-click="handleNodeClick" 
          :default-expand-all="true"
          :highlight-current="true" 
          :check-on-click-node="true">
        </el-tree>
      </div>
      <div class="content">
        <div class="js-common-head">
          <label class="label">功能与数据权限管理</label>
          <el-button type="primary" size="small" @click="handleSave">保存</el-button>
        </div>
        <div class="js-common-content js-content-roleDetails">
          <el-checkbox-group class="jurs-box" v-model="authority">
            <el-checkbox v-for="auth in authorityArr" :key="auth.id" :label="auth.id">{{auth.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      treeData: [],
      defaultProps: {
        children: 'deptList',
        label: 'name'
      },
      currentItem: '',
      authority: [],
      authorityArr: []
    }
  },
  mounted() {
    this.getCompany()
    this.getAuthorities()
  },
  methods: {
    getCompany(){
      this.$axios({
        method: 'get',
        url: 'api/csp/mgr/v1/dept/tree'
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.treeData = data.data
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    // 获取权限list
    getAuthorities(){
      this.$axios({
        method: 'get',
        url: 'api/csp/mgr/v1/permission/dept'
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.authorityArr = data.data
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    // 根据ID获取部门权限
    getAuthorityById(){
      this.$axios({
        method: 'get',
        url: `api/csp/mgr/v1/dept/permissionIds/${this.currentItem.id}`
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.authority = data.data
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    handleNodeClick(row){
      this.currentItem = row
      if(this.currentItem.id !== 1){
        this.getAuthorityById()
      }
    },
    handleSave(){
      console.log(this.currentItem)
      if(!this.currentItem.id || this.currentItem.id == 1){
        this.$message({
          type: 'error',
          message: '请选择你要设置权限的部门！',
          duration: 1000
        })
        return
      }
      this.$axios({
        method: 'post',
        url: `api/csp/mgr/v1/dept/bindRight`,
        data:{
          data: {
            deptId: this.currentItem.id,
            permissionIds: this.authority
          }
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.$message({
            type: 'success',
            message: '保存成功！',
            duration: 1000
          })
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/systemAuthority.scss';
</style>
